import styled from "styled-components";

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
`;

export default Button;
