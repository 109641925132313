import React from "react";
import Button from "../atoms/Button";
import BusinessInfo from "../assets/business_info.png";
import RushHour from "../assets/rushhour.png";
import InstagramButton from "../assets/instagram_button.webp";

const Main = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "768px",
        }}
      >
        <img src={RushHour} width={"100%"} />
        <form
          action={"https://www.instagram.com/rushhour_korea/"}
          style={{
            width: "80%",
            margin: "10px 0 100px 0",
          }}
        >
          <Button style={{}}>
            <img
              src={InstagramButton}
              className="instagram-button"
              width={"100%"}
            />
          </Button>
        </form>

        <img src={BusinessInfo} width={"100%"} />
      </div>
    </div>
  );
};

export default Main;
